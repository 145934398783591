
// import { mapGetters } from 'vuex';

import { required,email, helpers } from '@vuelidate/validators';



import useVuelidate from '@vuelidate/core';
const numeric = helpers.regex( /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);

// import { globalApiMixin } from '../../GlobalMixins/apiGlobalMixins';
import { defineComponent } from 'vue';
import { toast } from '../main';
export default defineComponent({

	// mixins: [globalApiMixin],
	name: 'app',
	data() :any{
		return {
            v$: useVuelidate(),
			onboarding: {
				organisationName: '',
				address: '',
				phoneNumber: '',
				website: '',
				domain: '',
				contactPerson: '',
				contactPersonEmail: '',
				subDomain: ''
			},
			isLoading: false
		};
	},
	validations() {

        return{
onboarding: {
			organisationName: { required },
			address: { required },
			contactPerson: { required },
			contactPersonEmail: { required, email },
			phoneNumber: { required ,numeric},
			website: { required },
			// domain: { required },
			// subDomain: {}
		}
        }
		
	},

	computed: {
		// ...mapGetters({
		// 	userInfo: 'userInfo'
		// })
	},
	methods: {
		addOrganization(payload:any) {
			this.$http
				.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_API_PATH}/onboarding`, payload)
				.then(() => {
					toast.success(`Organisations Added.`, {
						timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
					this.$router.back();
				})
				.catch(() => {
						toast.error(`Organisations  Not Added.`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				});
		},
		onSubmit() {
            console.log("save")
			this.submitted = true;
			this.v$.$touch();
			if (this.v$.$invalid) {
                 console.log("savedsdd")
				return;
			}
			let fullDomain = this.onboarding.contactPersonEmail.substring(this.onboarding.contactPersonEmail.lastIndexOf("@") + 1);
            let orgName = fullDomain.split('.')[0];
			let payload = {
				organizationName: this.onboarding.organisationName,
				orgLogo: '',
				address: this.onboarding.address,
				contactNumber: this.onboarding.phoneNumber,
				websiteUrl: this.onboarding.website,
				contactPerson: this.onboarding.contactPerson,
				tenantName: orgName,
				isActive: false,
				// createdBy: this.userInfo._id,
				createdAt: new Date(),
				// updatedBy: this.userInfo._id,
				updatedAt: new Date()
			};
			console.log('payload',payload);
			this.addOrganization(payload);
		}
	}
});
